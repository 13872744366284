<template>
  <div>
    <a-spin :spinning="spinning" tip="客官请稍等，小的马上就来">
      <a-table
        :pagination="pagination"
        :columns="columns"
        :data-source="data"
        rowKey="myid"
        :scroll="scroll"
      >
        <a slot-scope="text">{{ text }}</a>
        <span slot="customTitle">ID</span>
        <span slot="mystate" slot-scope="mystate">
          <a-tag :color="mystate === '已处理' ? 'green' : 'red'">
            {{ mystate }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a>Invite 一 {{ record.name }}</a>
      <a-divider type="vertical" />
      <a>Delete</a>
      <a-divider type="vertical" /> -->
          <a @click="() => del(record)" class="ant-dropdown-link">删除 </a>
          <a-divider type="vertical" />
          <a @click="() => recover(record)" class="ant-dropdown-link">处理</a>
          <a-divider type="vertical" />
        </span>
      </a-table>
    </a-spin>
  </div>
</template>
<script>
import SearchWaning from "./SearchWaning.vue";
import axios from "axios";
const columns = [
  {
    title: "设备ID",
    dataIndex: "myid",
    key: "myid",
  },
  {
    title: "数据ID",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "报警名称",
    dataIndex: "waningname",
    key: "waningname",
  },
  {
    title: "报警时间",
    dataIndex: "mytime",
    key: "mytime",
  },
  {
    title: "处理状态",
    dataIndex: "mystate",
    key: "mystate",
    scopedSlots: { customRender: "mystate" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "DeviceWaning",
  props: ["list"],
  data() {
    return {
      pagination: {
        pageSize: 7,
      },
      mypath: "",
      spinning: false,
      data: [],
      columns,
      mykey: 1,
    };
  },
  components: {
    SearchWaning,
  },
  watch: {
    //监听一个值的变化，然后执行对应函数

    list(n, o) {
      //遍历数组
      this.spinning = false;
      let this_1 = this;
      this.spinning = false;
      this.data = n.map((item_1) => {
        let state;
        if (item_1.status == "1") state = "已处理";
        else state = "未处理";
        return {
          //keyvalue: item_1.keyvalue,
          myid: item_1.id,
          name: item_1.dataId,
          waningname: item_1.name,
          mytime: new Date(parseInt(item_1.time))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " "),
          mystate: state,

          /*
         key: item.id,
         myid: item.hardWareID,
         name: item.name,
         mytime: this_1.formatTime(item.time).toString(),
         mystate:item.status.toString(),
         mytype:item.type*/
        };
      });
    },
  },
  created() {
    this.spinning = true;
  },
  methods: {
    recover(record) {
      let _this = this;
      _this.mypath = window.g.pro_alarm_deal;
      axios({
        url: _this.mypath,
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        params: {
          id: record.myid,
        },
      }).then((response) => {
        // this.devises = response.data.data
        // console.log(typeof(response.data))
        console.log(response);
        if (response.data.errorCode === 200) {
          _this.$message.success("处理成功，三秒后更新列表");
        } else if (response.data.msg === 44 || response.data.msg === 40) {
          _this.$message.error("权限认证失效，请重新登录");
          _this.$router.push("/IotPlatfrom/Loging");
        } else if (
          response.data.message === 44 ||
          response.data.message === 40
        ) {
          this_1.$message.error("权限认证失效，请重新登录");
          this_1.$router.push("/IotPlatfrom/Login");
        } else {
          _this.$message.error("处理失败，三秒后更新列表");
        }
      });
      //console.log(record.myid)
    },
    del(recode) {
      let _this = this;
      _this.mypath = window.g.pro_alarm_del;
      axios
        .delete(_this.mypath, {
          headers: {
            Authorization: localStorage.getItem("Authorization"),
          },
          params: {
            id: recode.myid,
          },
        })
        .then((response) => {
          // this.devises = response.data.data
          // console.log(typeof(response.data))
          //console.log(response)
          if (response.data.errorCode === 200) {
            _this.$message.success("删除设备成功，三秒后更新列表");
          } else if (response.data.msg === 44 || response.data.msg === 40) {
            _this.$message.error("权限认证失效，请重新登录");
            _this.$router.push("/IotPlatfrom/Loging");
          } else if (
            response.data.message === 44 ||
            response.data.message === 40
          ) {
            this_1.$message.error("权限认证失效，请重新登录");
            this_1.$router.push("/IotPlatfrom/Login");
          } else {
            _this.$message.error("删除设备失败，三秒后更新列表");
          }
        });
    },
  },
};
</script>