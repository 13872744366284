<template>
    <div>
         <Breadcrumb :home="devicetitle"/><!--管理上1-->
         <SearchWaning/><!--中间-->
         <DeviceWaning v-bind:list="devises"/><!--下1-->
    </div>
</template>
<script>
import SearchWaning from '../../../../components/IotPlatfrom/DeviceWaning/SearchWaning.vue'
import Breadcrumb from '../../../../components/IotPlatfrom/commont/Breadcrumb.vue'
import DeviceWaning from '../../../../components/IotPlatfrom/DeviceWaning/DeviceWaning.vue'
import axios from 'axios'
export default {
   
    data(){
      return{
        timer:null,
        mypath:"",
        devises:[],
               devicetitle:{
                path:"/IotPlatfrom/Buding",
                title:"设备管理",
                secondtitle:"设备报警"
            },
      }
    },
    components:{
         Breadcrumb,
         DeviceWaning,
         SearchWaning
    },
    beforeDestroy(){
    clearTimeout(this.timer)
  },

  created(){
     this.mytimer()
  },
  methods:{
     mytimer(){
       let this_ = this
       this_.timer = setInterval(function getdata() {
        
         this_.mypath = window.g.pro_alarmList
        axios.get(this_.mypath,{
            headers:{
          Authorization:localStorage.getItem('Authorization')
      }
         })
         .then(response =>{
          // console.log(response.data)
         // this.devises = response.data.data
          // console.log(typeof(response.data))
          if(response.data.message===44||response.data.message===40){
               this_.$message.error("权限认证失效，请重新登录")
                this_.$router.push('/Login');
          }
         this_.devises=response.data.data
        //console.log(response.data)
         
         })
      },2000)
    }
  },
}
</script>